import React, { useEffect } from "react";
import { strings as translate } from "locale";

import { useAsyncDebounce } from "react-table";

// @material-ui/core/styles
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const useStyle = makeStyles(styles);
const useSelectStyle = makeStyles(selectStyles);
// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  onChange,
  manualSearch,
  reset,
  showResetButton,
  onReset,
  onKeyUp,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const handleChange = useAsyncDebounce((value) => {
    if (!manualSearch) {
      setGlobalFilter(value || undefined);
    }
    if (onChange) {
      onChange(value);
    }
  }, 200);
  const classes = useStyle();

  useEffect(() => {
    if (reset > 0) {
      setValue("");
    }
  }, [reset]);

  return (
    <CustomInput
      id="search"
      formControlProps={{
        className: classes.filterFormControl,
      }}
      inputProps={{
        value: value || "",
        onChange: (e) => {
          setValue(e.target.value);
          handleChange(e.target.value);
        },
        placeholder: translate.search + "...",
        onKeyUp,
      }}
      reseteable
      showResetButton={showResetButton}
      onReset={() => {
        if (onReset) onReset();
        setValue("");
      }}
    />
  );
}

// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;
  const classes = useStyle();

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
        className: classes.filterFormControl,
      }}
      inputProps={{
        value: filterValue || "",
        onChange: (e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder:
          translate.formatString(translate.search_x_records, count) + "...",
      }}
    />
  );
};

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    console.log("SELECT COLUM FILTER", options.values());
    return [...options.values()];
  }, [id, preFilteredRows]);

  const classes = useSelectStyle();

  // Render a multi-select box
  return (
    <FormControl fullWidth className={classes.selectFilterFormControl}>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        displayEmpty={true}
        defaultValue={""}
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
        inputProps={{
          name: "filter-" + id,
          id: "filter-" + id,
        }}
      >
        <MenuItem
          value=""
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
        >
          All
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem
            key={i}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
const SliderColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
const NumberRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  const classes = useStyle();

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <CustomInput
        formControlProps={{
          className: classes.filterFormControl,
        }}
        inputProps={{
          type: "number",
          value: filterValue[0] || "",
          onChange: (e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
          },
          placeholder: `Min (${min})`,
          style: { maxWidth: "70px", marginRight: "0.5rem" },
        }}
      />
      <span className={classes.filterFormControl + " " + classes.textBetween}>
        to
      </span>
      <CustomInput
        formControlProps={{
          className: classes.filterFormControl,
        }}
        inputProps={{
          type: "number",
          value: filterValue[1] || "",
          onChange: (e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
          },
          placeholder: `Max (${max})`,
          style: { maxWidth: "70px", marginLeft: "0.5rem" },
        }}
      />
    </div>
  );
};

export {
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
};
