import React from "react";
import { trackPromise } from "react-promise-tracker";
import { strings as translate } from "locale";
import queryString from "query-string";
import _ from "lodash";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CustomTable from "components/CustomTable/CustomTable";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import useMainNotification from "../../hooks/useMainNotification";

// services
import MainServices from "../../services/MainServices";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(() => ({
  textError: {
    color: "#Ff0000",
    textAlign: "center",
    padding: "30px 0",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    fontWeight: "400",
  },
}));

const CustomDialog = (props) => {
  const {
    service: model,
    queryParams,
    id,
    linkTitle,
    linkButton,
    parentModel,
  } = props.data;
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState("");
  const { addError, addSuccess } = useMainNotification();
  const classes = useStyles();
  const [showAddButton, setShowAddButton] = React.useState(true);
  const [downloadUrl, setDownloadUrl] = React.useState("");

  async function loadData(pagination) {
    const qString = queryString.parse(queryParams);

    Object.keys(qString).forEach((k) => {
      let newKey = qString[k];
      if (qString[k].indexOf("#") === 0) {
        newKey = qString[k].substr(1);
        qString[k] = _.at(props.data.row, [newKey])[0];
      }
    });

    let args =
      pagination?.pageSize == undefined
        ? null
        : {
            page: pagination.pageIndex + 1,
            limit: pagination.pageSize,
            ...(pagination.search != "" && { search: pagination.search }),
            ...(pagination.sortBy?.length > 0 && {
              sortBy: pagination.sortBy
                .map((i) => `${i.id}:${i.desc ? "desc" : "asc"}`)
                .join(","),
            }),
          };

    try {
      const res = await MainServices.list(model, { ...args, ...qString });
      if (!res.data.error) {
        if (qString) {
          res.data.queryParams = qString;
        }

        if (res.data.data instanceof Array) {
          setData(res.data);
        } else {
          addError("Los datos no son válidos.", null, { messageLength: null });
        }

        if (
          res.data.config !== undefined &&
          res.data.config.button_add !== undefined
        ) {
          setShowAddButton(res.data.config.button_add);
        }
        if (
          res.data.config !== undefined &&
          res.data.config.download_url !== undefined
        ) {
          setDownloadUrl(res.data.config.download_url);
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        setError(e.response.data.error_msg);
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        setError(e.message);
        addError(e.message, null, { messageLength: null });
      } else {
        console.log(e);
      }
    }
  }

  React.useEffect(() => {
    if (model) {
      trackPromise(loadData()).then(() => {
        setLoaded(true);
      });
    }

    return () => {
      MainServices.cancel();
    };
  }, [addError, model, queryParams, id]);

  const crearLinkDePago = async () => {
    try {
      const res = await MainServices.getItem("create_payment_link", id);
      if (!res.data.error) {
        addSuccess("El link de pago fué creado exitosamente");
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        setError(e.response.data.error_msg);
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        setError(e.message);
        addError(e.message, null, { messageLength: null });
      } else {
        console.log(e);
      }
    }
  };

  const onClickActionButton = () => {
    if (linkButton.action === "crear_link_de_pago") {
      crearLinkDePago();
    }
  };

  const handleUpdateTableRow = (newRow) => {
    trackPromise(loadData()).then(() => {
      setLoaded(true);
    });
  };

  const deleteItem = async (id, parentQueryParams) => {
    let params = null;
    if (parentQueryParams) {
      const parentParams = parentQueryParams.split("/");
      const parentId = parentParams[1];
      const parentIdKey = parentParams[2];
      params = { [parentIdKey]: parentId };
    }
    try {
      const res = await MainServices.delete(model, id, params);
      return res;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      } else {
        console.log(e);
      }
    }
  };

  const handleDelete = async (id, parentQueryParams) => {
    let promise = new Promise((resolve, reject) => {
      deleteItem(id, parentQueryParams).then((res) => {
        if (res && !res.error) {
          addSuccess("El registro fue borrado exitosamente.");
          resolve("El registro fue borrado exitosamente.");
        }
      });
    });
    trackPromise(promise);
    let result = await promise;

    return result;
  };

  const downloadFile = (fileUrl) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    let filename = downloadUrl.substr(downloadUrl.lastIndexOf("/") + 1);
    filename = filename.substr(0, filename.indexOf("?"));
    link.setAttribute("download", filename);
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <Dialog
      {...props}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        {linkTitle ? linkTitle : "Title"}
      </DialogTitle>
      <DialogContent>
        <LoadingIndicator
          type="TailSpin"
          color="#2e82ef"
          height={50}
          width={50}
        />

        <GridContainer>
          <GridItem xs={6}>
            {loaded && showAddButton && (
              <Button
                id="btnAddTeam"
                className="btn btn-primary"
                onClick={() => {
                  props.history.push(
                    `/admin/${model ? model : "model"}/add${
                      parentModel
                        ? `/${parentModel}/${id}${
                            queryParams ? "/" + queryParams.split("=")[0] : ""
                          }`
                        : ""
                    }`
                  );
                }}
              >
                {translate.add}
              </Button>
            )}
          </GridItem>
          <GridItem xs={6} justify="flex-end" container>
            {loaded && downloadUrl && (
              <Button
                id="btnDownload"
                className="btn btn-primary"
                onClick={() => downloadFile(downloadUrl)}
              >
                {translate.download}
              </Button>
            )}
          </GridItem>
        </GridContainer>
        {loaded && !error && data ? (
          <CustomTable
            data={data}
            paginationBottom={true}
            updateTableRow={handleUpdateTableRow}
            pathCustomDialog={model ? `/admin/${model}` : null}
            onRowDeleted={handleDelete}
            parentQueryParams={`${parentModel}/${id}${
              queryParams ? "/" + queryParams.split("=")[0] : ""
            }`}
            fetchData={loadData}
          />
        ) : loaded && error ? (
          <div className={classes.textError}>
            {translate.formatString(translate.autogenerate_table_error, <br />)}
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        {linkButton && (
          <Button onClick={onClickActionButton} color="success">
            {linkButton.title}
          </Button>
        )}
        <Button onClick={props.onClose} color="primary">
          {translate.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
