import React from "react";

import GenericPageProvider from "../../providers/GenericPageProvider";

import List from "./List";
import Edit from "./Edit";
import Add from "./Add";

function GenericPage(props) {
  const { config } = props;
  const model = config.path.replace("/", "");
  const location = props.location.pathname;
  const newConfig = Object.assign(config, { model });

  if (location.lastIndexOf(`/${model}/add`) !== -1) {
    const regexp = new RegExp(
      `^/admin/${model}/add/([^\/]+)\/?([^\/]+)?\/?([^\/]+)?`,
      "i"
    );
    const match = location.match(regexp);
    const parent = match && match[1] ? match[1] : null;
    const id = match && match[2] ? match[2] : null;
    const id_key = match && match[3] ? match[3] : null;
    return (
      <GenericPageProvider config={newConfig}>
        <Add parentModel={parent} parentId={id} parentIdKey={id_key} />
      </GenericPageProvider>
    );
  }

  if (location.lastIndexOf(`/${model}/edit`) !== -1) {
    const regexp = new RegExp(
      `^/admin/${model}/edit/([^\/]+)\/?([^\/]+)?\/?([^\/]+)?\/?([^\/]+)?`,
      "i"
    );
    const match = location.match(regexp);
    const id = match[1] ? match[1] : null;
    const parent = match[2] ? match[2] : null;
    const parent_id = match && match[3] ? match[3] : null;
    const id_key = match && match[4] ? match[4] : null;
    return (
      <GenericPageProvider config={newConfig}>
        <Edit
          id={id}
          parentModel={parent}
          parentId={parent_id}
          parentIdKey={id_key}
        />
      </GenericPageProvider>
    );
  }

  return (
    <GenericPageProvider config={newConfig}>
      <List />
    </GenericPageProvider>
  );
}

export default GenericPage;
