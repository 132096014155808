/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3de46d2f-2bb9-42a2-9f5e-a20475e6d2a4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OkYBIlp2m",
    "aws_user_pools_web_client_id": "4cdbhkrk26um9oi0h7nbhtk2b7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "region": "us-east-2",
    "identityPoolRegion": "us-east-2",
    "userPoolId": "us-east-2_Z52WYAmy3",
    "userPoolWebClientId": "5jh1oi793lr88ns2pm67gfi803",
    "mandatorySignIn": false,
    "authenticationFlowType": "USER_SRP_AUTH",
    "Auth": {
        "region": "us-east-2",
        "identityPoolRegion": "us-east-2",
        "userPoolId": "us-east-2_Z52WYAmy3",
        "userPoolWebClientId": "5jh1oi793lr88ns2pm67gfi803",
        "mandatorySignIn": false,
        "authenticationFlowType": "USER_SRP_AUTH",
        "oauth": {}
    }
};


export default awsmobile;
