import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
  ...sweetAlertStyle,
  sweetAlertCustomClass: {
    "& span.btn": {
      top: "1.25rem !important",
      right: "1.25rem !important",
      fontFamily: "Verdana !important",
      cursor: "pointer",
    },
  },
  tableEmtpyCase: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px",
  },
  headerFilters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  customInputFilter: {
    flex: "1 1 100%",
    maxWidth: "250px",
  },
  customInputFilterManualPagination: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "flex-end",
    maxWidth: "none",
  },
  customButtonSearchManualPagination: {
    marginLeft: "16px",
    padding: "5px 26px",
    height: "36px",
  },
  tableTHead: {
    "& tr.rt-tr > th.rt-th:last-child, tr.rt-tr > th.rt-th:last-child div": {
      textAlign: "center",
    },
  },
  tableTBody: {
    "& tr > td:last-child": {
      textAlign: "center",
    },
  },
  paginationBottom: {
    paddingTop: "15px",
    "& .-pagination": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  paginationTop: {
    paddingBottom: "15px",
    "& .-pagination": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  globalFilterForm: {
    background: "rgba(0, 0, 0, 0.03)",
    borderRadius: "3px",
    width: "100%",
    margin: "0 0 15px 0",
  },
  globalFilterFormButton: {
    marginTop: "15px",
    width: "100%",
  },
};

export default styles;
